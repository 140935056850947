import React, { useState, useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
// import { SpotLightHelper } from 'three';


function Lights() {

  const lightRef = useRef();
  // const helperRef = useRef();

  const path = [
    new THREE.Vector3(-14.5, 7, -26), //A
    new THREE.Vector3(22.5, 10.5, -22), //B 
    new THREE.Vector3(45, 13, -9.7), //C
    new THREE.Vector3(50, 2.2, -9.5), //D
    new THREE.Vector3(36, 22, 4), //E
    new THREE.Vector3(44, 6, 8), //F
    new THREE.Vector3(32.5, 16, 15), //G
    new THREE.Vector3(21.5, 22, 17), //H
    new THREE.Vector3(12, 7.5, 31), //I
    new THREE.Vector3(14, 1.7, 36), //J
    new THREE.Vector3(-18, 11, 32), //K
    new THREE.Vector3(-36, 9, 18), //L
    new THREE.Vector3(-44, 4.2, 8), //M
    new THREE.Vector3(-40, 15, -18), //N
  ];

  const randomIndex = Math.floor(Math.random() * path.length);

  const [currentPoint, setCurrentPoint] = useState(randomIndex);
  const [interpolatedPosition, setInterpolatedPosition] = useState(path[randomIndex]);

  const interpolationSpeed = 0.01;
  // const [maxDistance, setMaxDistance] = useState(0);

  const { scene } = useThree();


  useEffect(() => {
    let maxDist = 0;
    for (let i = 0; i < path.length; i++) {
      const nextPoint = path[(i + 1) % path.length];
      const dist = path[i].distanceTo(nextPoint);
      if (dist > maxDist) {
        maxDist = dist;
      }
    }
    // setMaxDistance(maxDist);

    if (lightRef.current) {
      const targetObject = new THREE.Object3D();
      targetObject.position.set(0, 7, 0);
      scene.add(targetObject); // Add the target object to the scene
      lightRef.current.target = targetObject;

      // Update matrices
      lightRef.current.target.updateMatrixWorld();
      lightRef.current.updateMatrixWorld();

      // Create a helper for the spotlight (for debugging)
      // const helper = new SpotLightHelper(lightRef.current);
      // helperRef.current = helper;
      // scene.add(helper);
    }

    // Cleanup function to remove the helper when the component unmounts
    // return () => {
    //   if (helperRef.current) {
    //     scene.remove(helperRef.current);
    //   }
    // };
  }, [scene]); // eslint-disable-line react-hooks/exhaustive-deps

  useFrame(() => {
    if (lightRef.current) {
      const nextPointIndex = (currentPoint + 1) % path.length;
      const nextPoint = path[nextPointIndex];
      const distanceToNext = interpolatedPosition.distanceTo(nextPoint);
  
      if (distanceToNext < 0.1) {
        setCurrentPoint(nextPointIndex);
      } else {
        const totalDistance = path[currentPoint].distanceTo(nextPoint);
        const traveledDistance = totalDistance - distanceToNext;
        const progress = traveledDistance / totalDistance;
  
        // Adjust progress for sinusoidal easing
        const sinusoidalProgress = 0.5 - Math.cos(progress * Math.PI) / 2;
  
        const easingFactor = easeInOutCubic(sinusoidalProgress);
        const effectiveSpeed = Math.max(0.01, interpolationSpeed * easingFactor);
  
        setInterpolatedPosition(prev => prev.lerp(nextPoint, effectiveSpeed));
        lightRef.current.position.copy(interpolatedPosition);
      }
    }
  });
  
  function easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }
  
  

  return (
    <>
      <ambientLight intensity={.05} />
      <spotLight
        ref={lightRef}
        color="#ffffff"
        angle={Math.PI / 3}
        distance={100}
        decay={0}
        intensity={.65}
        penumbra={1.}
        castShadow
        shadow-mapSize-width={4096}
        shadow-mapSize-height={4096}
      />
    </>
  );
}

export default Lights;
