import create from 'zustand'

const useVideoManager = create((set) => ({
  didLoad: {
    video: false,
  },
  visible: {
    video: false,
  },
  setVisible: (type, loaded) =>
    set((state) => {
      return {
        ...state,
        visible: { [type]: loaded },
      }
    }),
  setLoaded: (type, loaded) =>
    set((state) => {
      return {
        ...state,
        didLoad: { [type]: loaded },
      }
    }),
  player: null,
  set: (key, value) =>
    set((state) => {
      return {
        ...state,
        [key]: value,
      }
    }),
    videos: {
      video1: null,
      video2: null,
    },
  
    setVideo: (key, videoElement) =>
      set((state) => ({
        ...state,
        videos: { ...state.videos, [key]: videoElement },
      })),
  
    playVideos: () => set((state) => {
      state.videos.video1.play();
      state.videos.video2.play();
    }),
}))

export default useVideoManager
