import { useRef } from "react";
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';

function Floor({
  size = 10,
  position = [0, -1, 0],
  colorMap,
  bumpMap,
  normalMap,
  roughnessMap,
  roughness = 0.2
}) {
  const planeRef = useRef();

  const tiling = 10;

  const [colorTexture, bumpTexture, normalTexture, roughTexture] = useLoader(THREE.TextureLoader, [colorMap, bumpMap, normalMap, roughnessMap]);

  colorTexture.wrapS = colorTexture.wrapT = THREE.RepeatWrapping;
  colorTexture.repeat.set(tiling, tiling);

  bumpTexture.wrapS = bumpTexture.wrapT = THREE.RepeatWrapping;
  bumpTexture.repeat.set(tiling, tiling);

  normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
  normalTexture.repeat.set(tiling, tiling);

  roughTexture.wrapS = roughTexture.wrapT = THREE.RepeatWrapping;
  roughTexture.repeat.set(tiling, tiling);



  return (
    <mesh
      ref={planeRef}
      rotation={[-Math.PI / 2, 0, 0]}
      position={position}
      receiveShadow
    >
      <planeGeometry args={[size, size]} />
      <meshStandardMaterial
        map={colorTexture}
        bumpMap={bumpTexture}
        normalMap={normalTexture}
        normalScale={0.1}
        roughnessMap={roughTexture}
        roughness={0}
        metalness={0}
      />
    </mesh>
  );
}

export default Floor;
