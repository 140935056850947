import { Vector3, EllipseCurve, BufferGeometry, LineBasicMaterial, Line } from 'three';

export function useEllipticalBoundary(camera, moveForward, moveBackward, moveLeft, moveRight, INCREMENT, scene, debug = false,enforceBoundary = true) {
  const a = 30; // Semi-major axis
  const b = 20;  // Semi-minor axis

  const isPositionWithinEllipse = (position) => {
    const x = position.x;
    const z = position.z;
    return (x * x) / (a * a) + (z * z) / (b * b) <= 1;
  };

  // Function to update camera position
  const updatePosition = () => {
    const direction = new Vector3();
    const frontVector = new Vector3(0, 0, Number(moveBackward) - Number(moveForward));
    const sideVector = new Vector3(Number(moveLeft) - Number(moveRight), 0, 0);

    direction.subVectors(frontVector, sideVector).normalize();
    camera.getWorldDirection(direction);
    direction.y = 0; // Restrict movement in the XZ plane

    let newPosition = camera.position.clone();
    newPosition.addScaledVector(direction, -INCREMENT * frontVector.z);
    const rotatedDirection = new Vector3(direction.z, 0, -direction.x);
    newPosition.addScaledVector(rotatedDirection, INCREMENT * sideVector.x);

    // console.log(newPosition);

    if (enforceBoundary && isPositionWithinEllipse(newPosition)) {
        camera.position.copy(newPosition);
      } else if (!enforceBoundary) {
        // If boundary enforcement is disabled, update position without check
        camera.position.copy(newPosition);
      }
  };

  // Function to create debug ellipse
  const createDebugEllipse = () => {
    const curve = new EllipseCurve(0, 0, a, b, 0, 2 * Math.PI, false, 0);
    const points = curve.getPoints(50);
    const geometry = new BufferGeometry().setFromPoints(points);
    const material = new LineBasicMaterial({ color: 0xff0000 });
    const ellipse = new Line(geometry, material);
    ellipse.rotation.x = Math.PI / 2; // Rotate to lie in the XZ plane
    ellipse.position.y = -4;
    scene.add(ellipse); // Assuming the camera is within a scene
  };

  if (debug) {
    createDebugEllipse();
  }

  return { updatePosition };
}
