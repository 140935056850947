import { useRef, useMemo, useEffect, useState } from "react";
import * as THREE from 'three';
import useVideoManager from "../hooks/useVideoManager";
import { useFrame } from '@react-three/fiber';


function VideoPlanes({
  size = 10,
  position = [0, -1, 0],
  videoFile
}) {
  const planeRef1 = useRef();
  const planeRef2 = useRef();

  // Separate video elements for each plane
  const [video1, setVideo1] = useState(null);
  const [video2, setVideo2] = useState(null);

  const setVideo = useVideoManager((state) => state.setVideo);

  // Create and manage the video elements
  useEffect(() => {
    const createVideo = (src) => {
      const vid = document.createElement('video');
      vid.src = src;
      vid.loop = true;
      vid.playbackRate = 1;
      return vid;
    };

    const vid1 = createVideo(videoFile);
    const vid2 = createVideo(videoFile);
    vid2.currentTime = 45;

    setVideo1(vid1);
    setVideo2(vid2);

    let timer = null;
    setVideo('video1', vid1);
    setVideo('video2', vid2);
    

    return () => {
      vid1.pause();
      vid2.pause();
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [videoFile, setVideo]);

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    // Adjust this value to control the speed of rotation
    const rotationSpeed = 0.05;

    if (planeRef1.current) {
      // Rotate the first plane
      planeRef1.current.rotation.y = elapsedTime * rotationSpeed;
    }

    if (planeRef2.current) {
      // Rotate the second plane at a constant 90 degrees offset from the first
      planeRef2.current.rotation.y = elapsedTime * rotationSpeed + Math.PI / 2;
    }
  });

  // Separate textures for each video
  const texture1 = useMemo(() => video1 ? new THREE.VideoTexture(video1) : null, [video1]);
  const texture2 = useMemo(() => video2 ? new THREE.VideoTexture(video2) : null, [video2]);


  const customDepthMaterial = useMemo(() => {
    const texture = texture1 || texture2;
    if (!texture) return null;

    return new THREE.ShaderMaterial({
      vertexShader: `
      varying vec2 vUV;

      void main() {
        vUV = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
      `,
      fragmentShader: `
      #include <packing>

      uniform sampler2D map;
      varying vec2 vUV;

      void main() {
        vec4 pixel = texture2D( map, vUV );
        if ( pixel.a < 0.3 ) discard;
        gl_FragColor = packDepthToRGBA( gl_FragCoord.z );
      }
      `,
      uniforms: {
        map: { value: texture }
      }
    });
  }, [texture1, texture2]);

  return (
    <>
      <mesh
        ref={planeRef1}
        position={position}
        castShadow
        receiveShadow
        customDepthMaterial={customDepthMaterial}
      >
        <planeGeometry args={[size, size]} />
        <meshStandardMaterial
        map={texture1}
          transparent={true}
          alphaMap={texture1}
          side={THREE.DoubleSide}
          emissive={"blue"}
          depthWrite={false}
        />
      </mesh>
      <mesh
        ref={planeRef2}
        position={position} // Adjust position to make them 90 degrees to each other
        rotation={[0, Math.PI / 2, 0]} // Rotate second plane 90 degrees
        castShadow
        receiveShadow
        customDepthMaterial={customDepthMaterial}
      >
        <planeGeometry args={[size, size]} />
        <meshStandardMaterial
        map={texture2}
          transparent={true}
          alphaMap={texture2}
          side={THREE.DoubleSide}
          emissive={"blue"}
          depthWrite={false}
        />
      </mesh>
    </>
  );
}

export default VideoPlanes;
