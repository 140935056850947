import React, { useEffect, Suspense, useState, useRef } from 'react'
import { useLoader, useThree } from '@react-three/fiber'
import { AudioLoader, AudioListener } from 'three'
import useAudioManager from '../hooks/useAudioManager'

function PositionalSoundCore({ id, start, data, buffer, ...props }) {
  const ref = useRef()
  const [listener] = useState(() => new AudioListener())
  const { camera } = useThree()

  useEffect(() => {
    camera.add(listener)
    return () => camera.remove(listener)
  }, [listener, camera])

  const loaded = useAudioManager((state) => state.didLoad[id])
  const setAudioRef = useAudioManager((state) => state.setAudioRef)

  useEffect(() => {
    const sound = ref.current
    if (sound) {
      setAudioRef(id, sound)
      sound.setBuffer(buffer)
      sound.setRefDistance(data[id].refdistance)
      sound.setRolloffFactor(data[id].rollofffactor)
      const { outer, inner, gain } = data[id].cone
      sound.setDirectionalCone(outer, inner, gain)
      sound.setMaxDistance(data[id].maxdistance)
      sound.setDistanceModel(data[id].distancemodel)
      sound.setLoop(true)
      sound.volume = data[id].volume
      sound.name = id
    }

    if (sound && loaded) {
      sound.play()
    }
    return () => {}
    // eslint-disable-next-line
  }, [id, buffer, loaded, data])

  useEffect(() => {
    const sound = ref.current;
    let timeoutId;

    const playSound = () => {
      if (sound && loaded && buffer) {
        sound.play();
        timeoutId = setTimeout(() => {
          sound.pause();
          playSound();
        }, buffer.duration * 1000 + data[id].delay);
      }
    };

    playSound();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      // Consider stopping the sound if the component is unmounting
      if (sound) {
        sound.stop();
      }
    };
  }, [loaded, data, id, buffer]);

  return (
    <Suspense fallback={null}>
      <positionalAudio
        ref={ref}
        args={[listener]}
        position={data[id].position}
      />
    </Suspense>
  )
}
function PositionalSound({ id, data }) {
  const setLoaded = useAudioManager((state) => state.setLoaded)

  const buffer = useLoader(AudioLoader, data[id].path, null, (xhr) => {
    if (xhr.loaded === xhr.total) {
      //const de = document.getElementById('debug-0')
      // de.innerHTML += 'Buffer ready: ' + id + '<br>'
      setLoaded(id, true)
    }
  })
  return (
    <Suspense fallback={null}>
      <PositionalSoundCore id={id} data={data} buffer={buffer} />
    </Suspense>
  )
}

export default PositionalSound
