import MobileViewer from './components/MobileViewer.js'
import Audio from './components/Audio.js'
import Lights from './components/Lights.js'
import Viewer from './components/Viewer.js'
import Model from './components/Model.js'
import Stream from './components/StreamSimple.js'
import { Canvas } from '@react-three/fiber'
import { useState, Suspense } from 'react'
import { isMobile } from 'react-device-detect'
import ErrorBoundary from './components/ErrorBoundary.js'
import { hot } from 'react-hot-loader/root'
import useUIManager from './hooks/useUIManager.js'
import Skybox from './components/Skybox.js'
import Floor from './components/Floor.js'
import VideoPlanes from './components/VideoPlanes.js'
import CookieBot from 'react-cookiebot'
// import { SceneContext } from './components/SceneContext.js';


// Google Analytics
// import ReactGA from 'react-ga';
// const TRACKING_ID = "G-E46RZMS4MB";
// ReactGA.initialize(TRACKING_ID, { debug: true });
import ReactGA from "react-ga4";
ReactGA.initialize("G-E46RZMS4MB");

// Cookieboy
const domainGroupId = 'e42928a8-c447-4816-87f3-1bca01454a9b';

function App() {
  const [startVideoPlayback, setStartVideoPlayback, hasCookieBot, setHasCookieBot] = useState(null);

  const setUserinteracion = () => {
    if (startVideoPlayback) {
      startVideoPlayback();
    }
  };

  const transition = useUIManager((state) => state.transition)
  const cameraOptions = {
    far: 100,
    fov: 60,
    aspect: window.width / window.height,
    position: [0, 0, 0],
    rotation: [.3, 0, 0],
  }

  const modelPath = `/models/`;

  const imageUrl = "/gradient.jpg"

  const SceneWrapper = () => {
    // const { scene } = useThree(); // This is the correct place to use useThree
    // console.log(scene);
    return <Lights />;
  };

  return (
    <>
      <CookieBot domainGroupId={domainGroupId} />
      <ErrorBoundary>
        <Canvas dpr={[2, 1]} shadows camera={cameraOptions}>
          <Suspense fallback={null}>
            <Model
              scale={1}
              name="Cave"
              path={modelPath}
              mtlFile="cave.mtl"
              objFile="cave.obj"
              position={[0, -1, 0]}
              colorMap="/models/tex/Rock_033_baseColor.jpg"
              bumpMap="/models/tex/Rock_033_height.png"
              specularMap="/models/tex/Rock_033_roughness.jpg"
              normalMap="/models/tex/Rock_033_normal.jpg"
              aoMap="/models/tex/aomap.jpg"
            />
            <Floor
              size={100}
              position={[0, -2, 0]}
              colorMap="/models/tex/concrete_0019_color_2k.jpg"
              bumpMap="/models/tex/concrete_0019_height_2k.png"
              normalMap="/models/tex/concrete_0019_normal_directx_2k.jpg"
              roughnessMap="/models/tex/concrete_0019_roughness_2k.jpg"
            />
            {!transition ? (
              <>
                {!isMobile ? (
                  <Viewer
                    position={[0, 0, 0]}
                    callback={setUserinteracion}
                  />
                ) : (
                  <MobileViewer callback={setUserinteracion} />
                )}
              </>
            ) : null}

            <Audio />
            <SceneWrapper />
            {/* <Lights/> */}
            <VideoPlanes
              size={20}
              rotation={[0, Math.PI, 0]}
              position={[0, 7, 0]}
              startVideos={setStartVideoPlayback}
              videoFile="/videos/cloud.webm"
            />
            <Stream
              url={'https://www.youtube.com/watch?v=u4O0wthnhCU'}
              position={[0, 1, 0]}
              rotation={[0, Math.PI / 2, 0]}
              scale={0.0}
            />
            <Skybox imageUrl={imageUrl} />
          </Suspense>
        </Canvas>
      </ErrorBoundary>
      {/* <GUIControls /> */}
    </>
  )
}

export default hot(App)
