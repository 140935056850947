import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { TextureLoader } from 'three';
import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';

function Model({ path, objFile, mtlFile, position, scale, colorMap, normalMap, bumpMap, specularMap, aoMap,  ...props }) {
  const { gl } = useThree(); // This is needed to access the renderer
  const [model, setModel] = useState();

  useEffect(() => {
    const textureLoader = new TextureLoader();

    const tiling = 15;
    const color = textureLoader.load(colorMap);
    const normal = textureLoader.load(normalMap);
    const bump = textureLoader.load(bumpMap);
    const specular = textureLoader.load(specularMap);
    const ao = textureLoader.load(aoMap);

    color.wrapS = color.wrapT = THREE.RepeatWrapping;
    color.repeat.set(tiling, tiling);

    normal.wrapS = normal.wrapT = THREE.RepeatWrapping;
    normal.repeat.set(tiling, tiling);

    bump.wrapS = bump.wrapT = THREE.RepeatWrapping;
    bump.repeat.set(tiling, tiling);

    specular.wrapS = specular.wrapT = THREE.RepeatWrapping;
    specular.repeat.set(tiling, tiling);

    const mtlLoader = new MTLLoader();
    mtlLoader.setPath(path);
    mtlLoader.load(mtlFile, (materials) => {
      materials.preload();
      const objLoader = new OBJLoader();
      objLoader.setMaterials(materials);
      objLoader.setPath(path);
      objLoader.load(objFile, (object) => {
        object.traverse((child) => {
          if(child.isMesh){
            child.castShadow = true;
            child.receiveShadow = true;

            // child.material.map = color;
            // child.material.aoMap = ao;
            // child.material.normalMap = normal;
            // child.material.bumpMap = bump;
            // child.material.specularMap = specular;
            // child.material.aoMapIntensity = 1;
            child.material = new THREE.MeshPhongMaterial({
              map: color,
              color: "lightgrey",
              aoMap: ao,
              normalMap: normal,
              bumpMap: bump,
              specularMap: specular,
              // specular: new THREE.Color('pink'),
              shininess: 1,
              aoMapIntensity: 1,
              normalScale: new THREE.Vector2(0.7, 0.7),
            });

            const maxAnisotropy = gl.capabilities.getMaxAnisotropy();
          if (child.material.map) {
            child.material.map.anisotropy = maxAnisotropy;
            child.material.map.minFilter = THREE.LinearMipmapLinearFilter;
            child.material.map.needsUpdate = true;
          }
          if (child.material.bumpMap) {
            child.material.bumpMap.anisotropy = maxAnisotropy;
            child.material.bumpMap.minFilter = THREE.LinearMipmapLinearFilter;
            child.material.bumpMap.needsUpdate = true;
          }
          if (child.material.normalMap) {
            child.material.normalMap.anisotropy = maxAnisotropy;
            child.material.normalMap.minFilter = THREE.LinearMipmapLinearFilter;
            child.material.normalMap.needsUpdate = true;
          }
          if (child.material.specularMap) {
            child.material.specularMap.anisotropy = maxAnisotropy;
            child.material.specularMap.minFilter = THREE.LinearMipmapLinearFilter;
            child.material.specularMap.needsUpdate = true;
          }
          if (child.geometry.attributes.uv2 === undefined) {
            child.geometry.setAttribute('uv2', new THREE.BufferAttribute(child.geometry.attributes.uv.array, 2));
          }
        }
        });
        setModel(object);
      });
    });
  }, [path, objFile, mtlFile, gl, colorMap, normalMap, bumpMap, specularMap, aoMap]);

  return model ? (
    <primitive object={model} position={position} scale={scale} {...props} />
  ) : null;
}

export default Model;