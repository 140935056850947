import { useEffect, useRef, useCallback } from 'react';
import { AudioListener, AudioLoader, Audio } from 'three';

function GlobalSound({ path, loop = true, autoplay = true, interval = 0, volume = 1, enablePanning = false }) {
    const sound = useRef(null);
    const intervalRef = useRef(null);
    const pannerRef = useRef(null);
    const listener = useRef(new AudioListener());

    // Memoize the playSound function
    const playSound = useCallback(() => {
        if (sound.current && sound.current.isPlaying) {
            sound.current.stop();
        }
        // Update pan value if panning is enabled
        if (enablePanning && pannerRef.current) {
            const newPanValue = Math.random() * 2 - 1; // generates a value between -1 and 1
            pannerRef.current.pan.setValueAtTime(newPanValue, listener.current.context.currentTime);
        }
        sound.current.play();
    }, [enablePanning]); // Include dependencies here

    useEffect(() => {
        sound.current = new Audio(listener.current);
        const audioLoader = new AudioLoader();

        if (enablePanning) {
            // Create a StereoPannerNode
            pannerRef.current = listener.current.context.createStereoPanner();
        }

        audioLoader.load(path, (buffer) => {
            sound.current.setBuffer(buffer);
            sound.current.setLoop(loop);
            sound.current.setVolume(volume);

            if (enablePanning) {
                // Connect the audio source to the panner and then to the listener
                sound.current.getOutput().disconnect();
                sound.current.getOutput().connect(pannerRef.current).connect(listener.current.context.destination);
            }

            if (autoplay) {
                playSound();
            }
        });

        if (interval > 0) {
            intervalRef.current = setInterval(playSound, interval);
        }

        return () => {
            if (sound.current) {
                sound.current.stop();
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [path, loop, autoplay, interval, volume, enablePanning, playSound]); // Include playSound in the dependency array

    return null;
}

export default GlobalSound;
