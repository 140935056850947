import PositionalSound from './PositionalSound.js'
import GlobalSound from './GlobalSound.js'
import useAudioManager from '../hooks/useAudioManager'
import useUIManager from '../hooks/useUIManager'
import { useEffect } from 'react'

const okarina1 = createAudioObject([-30, 0, 0], '/audio/Plato-Canary-Okarina_1.mp3')
const okarina2 = createAudioObject([30, 0, 0], '/audio/Plato-Canary-Okarina_2.mp3')
const okarina3 = createAudioObject([0, 0, 14], '/audio/Plato-Canary-Okarina_3.mp3')
const okarina4 = createAudioObject([0, 0, -18], '/audio/Plato-Canary-Okarina_4.mp3')

function createAudioObject(position, path) {
  return {
      audio: {
          position: position,
          path: path,
          refdistance: 10,
          rollofffactor: 5,
          cone: {
              outer: 360,
              inner: 180,
              gain: 1,
          },
          rotation: [0, 0, 0],
          maxdistance: 25,
          distancemodel: 'exponential',
          delay: 5000,
          loop: true
      },
  };
}

function Audio() {
  const allLoaded = useAudioManager((state) => state.allLoaded)
  const setResource = useUIManager((state) => state.resourceLoaded)

  useEffect(() => {
    if (allLoaded) {
      setResource('audio', true)
    }
  }, [allLoaded, setResource])

  return (
    <>
      <GlobalSound loop={false} path={'/audio/Plato-Canary_Canary.mp3'} interval={120000} volume={0.5} enablePanning={true} autoplay={false} /> 
      <GlobalSound path={'/audio/Plato-Canary_Geofon+dropp.mp3'} volume={0.8} /> 
      <PositionalSound id={'audio'} data={okarina1} />
      <PositionalSound id={'audio'} data={okarina2} />
      <PositionalSound id={'audio'} data={okarina3} />
      <PositionalSound id={'audio'} data={okarina4} />
    </>
  )
}

export default Audio
